import React from "react";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div id="footer-div">
      Copyright © {currentYear} <a href="https://bettertrucks.com/">Better Trucks WebTracking</a>. All rights reserved.
    </div>
  );
};

export default Footer;
