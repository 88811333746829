import React, { useEffect, useState } from "react";
import "./App.css";
import MapPreview from "./components/MapPreview";
import { AddressResponse, AddressService } from "services/AddressService";
import Spinner from "helpers/Spinner";
import { error, success } from "helpers/notification";
import {
  HelpersService,
  StatesDropdownResponse,
} from "services/HerlpersService";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import _default from "@mui/material/styles/identifier";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Footer from "components/Footer";
import Header from "components/Header";
import AddressError from "components/AddressError";
import { AddressConfirmationModal } from "modals/AddressConfirmationModal";
import AddressWarning from "components/AddressWarning";
import config from "config";

const App: React.FC = () => {
  const [location, setLocation] = useState<{ lat: number; lng: number }>({
    lat: 39.8283,
    lng: -98.5795,
  });
  // Find the default state object based on correctedAddress.state
  const [defaultState, setDefaultState] =
    useState<StatesDropdownResponse | null>({
      code: "AL",
      name: "Alabama",
    });
  const [trackingNumber, setTrackingNumber] = useState<string>("");
  const [addressResponse, setAddressResponse] =
    useState<AddressResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [updateBtnText, setUpdateBtnText] = useState<string>("Update address");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isShowMap, setIsShowMap] = useState<boolean>(false);
  const [is404Error, setIs404Error] = useState<boolean>(false);

  const [correctedAddress, setCorrectedAddress] = useState({
    street1: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    latitude: 0,
    longitude: 0,
  });

  const [statesDropdown, setStatesDropdown] = useState<
    StatesDropdownResponse[]
  >([]);

  const handleSelectLocation = (lat: number, lng: number) => {
    console.log("handleSelectLocation (lat,lng) --  ", lat, lng);
    setLocation({ lat, lng });
  };

  const handleAddressUpdate = () => {
    // e.preventDefault(); // Prevent default form submission behavior

    setUpdateBtnText("Updating address...");

    setIsModalOpen(true);

    correctedAddress.latitude = location.lat;
    correctedAddress.longitude = location.lng;

    AddressService.updateParcelAddress(
      addressResponse?.id || 0,
      trackingNumber,
      isShowMap,
      correctedAddress
    )
      .then((response: any) => {
        setAddressResponse(response.data);
        setCorrectedAddress({
          street1: response.data.street1 || "",
          street2: response.data.street2 || "",
          city: response.data.city || "",
          state: response.data.state || "",
          zip: response.data.zip || "",
          latitude: response.data.latitude || 0,
          longitude: response.data.longitude || 0,
        });
        setIsLoading(false);

        if (response.data.latitude && response.data.longitude) {
          setLocation({
            lat: response.data.latitude,
            lng: response.data.longitude,
          });
        }

        success("Address updated successfully");
        setUpdateBtnText("Update address");
        setIsShowMap(false);
      })
      .catch((_error: any) => {
        setUpdateBtnText("Update address");
        console.log("Address update error: ", _error);
        error(
          "Address could not be verified. Please drop a pin on the map to confirm your delivery location"
        );
        setIsShowMap(true);
      });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const trackingNumber = urlParams.get("trackingNumber");

    if (trackingNumber) {
      setTrackingNumber(trackingNumber);
      setIsLoading(true); // Set loading state at the beginning

      // Get Parcel Address
      AddressService.getParcelAddress(trackingNumber)
        .then((response: any) => {
          const addressData = response.data;
          setAddressResponse(addressData);
          setCorrectedAddress({
            street1: addressData.street1 || "",
            street2: addressData.street2 || "",
            city: addressData.city || "",
            state: addressData.state || "",
            zip: addressData.zip || "",
            latitude: addressData.latitude || 0,
            longitude: addressData.longitude || 0,
          });

          if (addressData.latitude && addressData.longitude) {
            setLocation({
              lat: addressData.latitude,
              lng: addressData.longitude,
            });
          }

          // Return the address data to chain the next promise
          return addressData;
        })
        .then((addressData) => {
          // Get States Dropdown after the parcel address is fetched
          return HelpersService.getStatesDropdown().then((response: any) => {
            setStatesDropdown(response.data);

            // Find the default state object based on correctedAddress.state
            const _defaultState = response.data.find(
              (state: StatesDropdownResponse) =>
                state.code === addressData.state
            );

            setDefaultState(_defaultState);
          });
        })
        .catch((_error: any) => {
          console.log("Error response  --  ", _error.response);

          if (_error.response && _error.response.status === 404) {
            setIs404Error(true);
            console.log("404 error  --  ", _error.response);
          }

          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false); // Set loading state to false after all requests are completed
        });
    }
  }, []);

  useEffect(() => {
    // console.log("correctedAddress  --  ", correctedAddress);
    // console.log("addressResponse  --  ", addressResponse);
  }, [correctedAddress, addressResponse]);

  // This function will handle input changes and update the state
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setCorrectedAddress((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleStateInputChange = (value: any) => {
    if (value) {
      setCorrectedAddress((prevState) => ({
        ...prevState,
        state: value.code,
      }));
    }
  };

  return (
    <div className="App">
      <Header />

      <div id="parcel-tracking">
        <div id="parcel-tracking-left">
          <div id="tracking-text" className="roboto-capital">
            Tracking Number
          </div>
          <div id="tracking-number">{trackingNumber}</div>
        </div>
        <div id="parcel-tracking-right">
          <Button
            variant="contained"
            onClick={() =>
              window.open(
                `${config.TRACKING_URL}Tracking?ShipmentId=${trackingNumber}`,
                "_blank"
              )
            }
          >
            Track your package
          </Button>
        </div>
      </div>

      {isLoading == true ? (
        <div id="spinner-div">
          <Spinner></Spinner>
        </div>
      ) : (
        <>
          {!addressResponse?.googleValidated &&
            (is404Error ? <AddressWarning /> : <AddressError />)}

          {addressResponse && (
            <div className="main-content">
              <div className="row address-section">
                <div className="col">
                  <div className="row">
                    <div className="col-address">
                      <div className="form-title">Original Address</div>
                      <div>
                        <form>
                          <div className="inputField">
                            <TextField
                              fullWidth
                              disabled
                              id="outlined-disabled"
                              label="Address Line 1"
                              defaultValue={addressResponse?.street1}
                            />
                          </div>

                          <div className="inputField">
                            <TextField
                              fullWidth
                              disabled
                              id="outlined-disabled"
                              label="Address Line 2"
                              defaultValue={addressResponse?.street2}
                            />
                          </div>

                          <div className="inputField">
                            <TextField
                              fullWidth
                              disabled
                              id="outlined-disabled"
                              label="State"
                              defaultValue={defaultState?.name}
                            />
                          </div>

                          <div className="input-group">
                            <div className="input-wrapper city-field-div">
                              <TextField
                                fullWidth
                                disabled
                                id="outlined-disabled"
                                label="City"
                                defaultValue={addressResponse?.city}
                              />
                            </div>

                            <div className="input-wrapper zip-field-div">
                              <TextField
                                fullWidth
                                disabled
                                id="outlined-disabled"
                                label="Zip Code"
                                defaultValue={addressResponse?.zip}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-address">
                      <div className="form-title">Enter corrected address</div>
                      <div>
                        <form>
                          <div className="inputField">
                            <TextField
                              fullWidth
                              required
                              id="street1"
                              label="Address Line 1"
                              defaultValue={correctedAddress?.street1}
                              onChange={(e) => handleInputChange(e)}
                            />
                          </div>

                          <div className="inputField">
                            <TextField
                              fullWidth
                              // required
                              id="street2"
                              label="Address Line 2"
                              defaultValue={correctedAddress?.street2}
                              onChange={(e) => handleInputChange(e)}
                            />
                          </div>

                          <div className="inputField">
                            <Autocomplete
                              id="id"
                              fullWidth
                              options={statesDropdown}
                              autoHighlight
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => {
                                const { key, ...optionProps } = props;
                                return (
                                  <Box
                                    key={key}
                                    component="li"
                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                    {...optionProps}
                                  >
                                    {option.name}
                                  </Box>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="State"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                              defaultValue={defaultState}
                              onChange={(event, value) =>
                                handleStateInputChange(value)
                              }
                            />
                          </div>

                          <div className="input-group inputfield">
                            <div className="input-wrapper city-field-div">
                              <TextField
                                fullWidth
                                required
                                id="city"
                                label="City"
                                defaultValue={correctedAddress?.city}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </div>

                            <div className="input-wrapper zip-field-div">
                              <TextField
                                fullWidth
                                required
                                id="zip"
                                label="Zip Code"
                                defaultValue={correctedAddress?.zip}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </div>
                          </div>
                          <div className="inputButton">
                            <Button
                              fullWidth
                              variant="contained"
                              size="large"
                              onClick={(e) => setIsModalOpen(true)}
                            >
                              {updateBtnText}
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {isShowMap && (
                  <div className="col">
                    <MapPreview
                      initialCenter={location}
                      onSelectLocation={handleSelectLocation}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          <AddressConfirmationModal
            fullName={addressResponse?.name || ""}
            isOpen={isModalOpen}
            toggleModal={() => setIsModalOpen(!isModalOpen)}
            updateAddress={handleAddressUpdate}
          />
        </>
      )}

      <Footer />
    </div>
  );
};

export default App;
